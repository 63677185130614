@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* --------------- Speciální nastavení pro použité komponenty --------------- */
/* fix calendar icons color in dark mode */
@media (prefers-color-scheme: dark) {
  input[type='datetime-local']::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}

input[type='datetime-local']::-webkit-calendar-picker-indicator {
  @apply cursor-pointer;
}

@media (prefers-color-scheme: dark) {
  input[type='date']::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}

input[type='date']::-webkit-calendar-picker-indicator {
  @apply cursor-pointer;
}

.reactMarkDown ul {
  list-style: disc;
  padding-left: 1rem;
}

/*--- Navod ---*/
.navod h1 {
  @apply text-3xl font-bold mb-4 border-b border-gray-300 pb-2
}
.navod h2 {
  @apply text-2xl font-bold mb-3
}
.navod h3 {
  @apply text-xl font-bold mb-2
}
.navod h4 {
  @apply text-lg font-bold mb-2
}
.navod h5 {
  @apply text-base font-bold mb-2
}
.navod p {
  @apply text-base mb-2
}
.navod ul {
  @apply list-disc list-inside mb-2
}
.navod a {
  @apply cursor-pointer underline font-semibold
}
.navod img {
  @apply inline-block align-top dark:bg-gray-300 rounded-sm
}
.navod button {
  @apply bg-gray-300 px-1 rounded-lg border border-gray-500 cursor-default dark:text-gray-700
}

/* ---------------- Úvodní okno iPES ----------------- */
/* Hlavička */
.hlokno-hlavicka-pozadi{
  @apply z-10 py-4 bg-white dark:bg-gray-800 shadow-md dark:shadow-md
}
.hlokno-hlavicka-obsah{
  @apply container flex items-center justify-between h-full px-6 mx-auto transition-all duration-300 text-amber-600 hover:text-amber-700 dark:text-amber-500 hover:dark:text-amber-600
}
.hlokno-hlavicka-nadpis{
  @apply relative max-w-xl mr-6 text-center text-gray-800 font-bold text-3xl px-5 bg-amber-500 dark:bg-amber-500 rounded-3xl border-amber-600 border-2  shadow-amber-800 shadow-inner transition-shadow
  /* @apply relative max-w-xl mr-6 text-center text-black text-gray-800 font-bold text-3xl px-5 bg-gray-200 dark:bg-gray-400 rounded-lg border-amber-600 border-2 shadow-lg shadow-amber-500 */
}
.hlokno-hlavicka-refresh{
  @apply text-amber-600 hover:text-amber-700 dark:text-amber-500 hover:dark:text-amber-600 
}
.hlokno-hlavicka-notifikace{
  @apply relative align-middle rounded-md focus:outline-none cursor-pointer
}
.hlokno-hlavicka-notifikace-aktivni{
  @apply absolute top-0 right-0 inline-block w-5 h-5 transform translate-x-1 -translate-y-1 bg-red-600 border-2 border-white rounded-full dark:border-gray-800
}
.hlokno-hlavicka-notifikace-seznam{
  @apply justify-between cursor-default text-gray-900 bg-gray-200 dark:text-gray-300 dark:bg-gray-800
}


/* ---------------- Celá APLIKACE (obecné třídy) ----------------- */
/*----- Malé Ikonky -----*/
/* Střední velikost  - např. info */
.ikonka-vzhled {
  @apply text-sm text-gray-400 transition-all hover:text-red-600 cursor-pointer
}
.ikonka-velikostM {
  @apply w-5 h-5
}
.ikonka-velikostS {
  @apply w-4 h-4
}
.ikonka-velikostL {
  @apply w-6 h-6
}

/*----- Nápověda -----*/
/* ikona s otazníkem */
.napoveda-ikona-otaznik
{
  @apply w-8 h-8 text-amber-600 hover:text-amber-700 dark:text-amber-500 hover:dark:text-amber-600 transition duration-300
}

/*--- POLE pro vyhledávání ---*/
.vyhledavani-div {
  @apply flex flex-row gap-2 flex-wrap
}
.vyhledavani-input {
  @apply w-[350px] bg-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:placeholder-gray-500 px-2 py-1 rounded-md placeholder-gray-400 mb-1 border border-gray-900
  hover:bg-gray-200 hover:dark:bg-gray-700 hover:placeholder-gray-600 hover:dark:placeholder-gray-300
}
.vyhledavani-tlacitko {
  @apply text-center w-[130px] shadow-md hover:shadow-lg cursor-pointer bg-gray-400 text-white rounded-md px-2 py-1 mb-1 transition duration-500 select-none hover:bg-gray-500 focus:outline-none focus:shadow
}

/*--- Select pro výběr zaměstnance ---*/
.vyber-zamestnance-select {
  @apply font-bold cursor-pointer text-base py-2 px-2 bg-gray-300 dark:bg-gray-700 rounded-md hover:bg-gray-400 hover:dark:bg-gray-600
}
.vyber-zamestnance-option {
  @apply bg-gray-300 dark:bg-gray-700
}

/*--- Tabulky - obecné třídy ---*/
.tabulka-MinMaxVelikost {
  @apply relative min-h-[600px] max-h-[700px] overflow-auto
}
/* Záhlaví - ukotvené (sticky) */ 
.tabulka-zahlavi-sticky {
  @apply sticky top-0 bg-white dark:bg-gray-800 
}
.tabulka-zahlavi-pozadi {
  @apply bg-gray-100 dark:bg-gray-700 
}
/* tabulka - původní šedé Záhlaví (kapitálky) - původně bylo v zápisu docházky */ 
.tabulka-zahlavi-VelkePismo {
  @apply  text-left text-xs font-medium text-gray-500 uppercase tracking-wider
}
/* tabulka - Záhlaví - zápis docházky */ 
.tabulka-zahlavi-zapisdoch {
  @apply bg-gray-100 dark:bg-gray-700 text-left text-sm font-medium text-gray-800 dark:text-gray-300
}
/* tabulka - Záhlaví - sticky verze pro administraci */ 
.tabulka-zahlavi-sticky-admin {
  @apply pr-2 py-1 text-sm font-semibold sticky top-0 bg-gray-100 dark:bg-gray-700 
}
.tabulka-radek-hover {
  @apply hover:bg-gray-200 dark:hover:bg-gray-900 transition duration-300
  /* hover:bg-gray-100 dark:hover:bg-gray-900 */
}
.tabulka-telo-Xramecek {
  @apply border-x border-gray-100 dark:border-gray-700
}
/* vodorovné linky mezi řádky (viz. docházka zápis)*/
.tabulka-telo-Ylinka {
  @apply divide-y divide-gray-200 dark:divide-gray-700 w-full
}
/* základní styl buňky tabulky (použito např. v administraci)*/
.tabulka-bunka-basic {
  @apply pr-2 font-normal text-base text-black dark:text-gray-300
}

/*----- Tlačítka (obecná a docházka) - plocha a hlavní okna -----*/
/* dialog - tlačítko Zavřít (šedé) */
.tlacitko-dialog-zavrit {
  @apply cursor-pointer text-gray-800 hover:bg-gray-300 border-gray-300 text-center w-auto text-sm transition duration-500 rounded-md border shadow-sm px-3 sm:px-4 py-2 bg-white font-medium hover:cursor-pointer focus:outline-none hover:shadow-lg dark:shadow-gray-700 dark:hover:shadow-gray-600
}
/* Přehledy - "světlá amber" */
.tlacitko-dialog-prehledy-svetle {
  @apply text-white bg-amber-500 hover:bg-amber-600 cursor-pointer shadow-md hover:shadow-lg rounded-md px-3 sm:px-4 py-2 transition duration-500 select-none focus:outline-none dark:shadow-gray-700 dark:hover:shadow-gray-600
}
/* Přehledy - "tmava amber" */
.tlacitko-dialog-prehledy-tmave {
  @apply text-white bg-amber-600 hover:bg-amber-700 cursor-pointer shadow-md hover:shadow-lg rounded-md px-3 sm:px-4 py-2 transition duration-500 select-none focus:outline-none dark:shadow-gray-700 dark:hover:shadow-gray-600
}


/*--- OKNA ---*/
/* Dialogové okno - rozostření pozadí - použitelné pro všechny typy oken */
.dialog-transition {
  @apply fixed inset-0 bg-gray-500 dark:bg-gray-700 dark:bg-opacity-80 bg-opacity-75 transition-opacity
}
/* Dialogové okno - rámeček - normální datové okno */
.dialog-ramecek {
  @apply relative rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:w-fit
}
/* Dialogové okno - nadpis */
.dialog-nadpis {
  @apply text-lg leading-6 px-2 py-1 font-medium dark:text-gray-300 text-gray-900 bg-gray-100 dark:bg-gray-700 rounded w-fit border dark:border-gray-500
}
/* Dialogové okno - obsah */
.dialog-obsah {
  @apply bg-white dark:bg-gray-800 dark:text-gray-300 px-4 pt-5 pb-4 sm:p-6 sm:pb-4
}
/* Dialogové okno - obsah - umístění*/
.dialog-obsah-umisteni {
  @apply mt-1 text-center sm:mt-0 sm:ml-1 sm:text-left
}
/* Dialogové okno - obsah - umístění - větší mezery */
.dialog-obsah-umisteni2 {
  @apply mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left
}
/* Dialogové okno - zápatí - pozadí a umístění tlačítek*/
.dialog-zapati-pozadi {
  @apply bg-gray-100 dark:bg-gray-900 dark:text-gray-300 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse
}
/* Dialogové okno - zápatí - zarovnání tlačítek*/
.dialog-zapati-zarovnani {
  @apply flex flex-row gap-2 flex-wrap justify-center
}


/* ----- Dotazovací okna ----- */
/* Využito i pro chybová hlášení (error.jsx) */
/* Dialogové okno - rámeček - potvrzovací (dotazové okno*/
.dialog-dotaz-ramecek {
  @apply relative transform overflow-hidden rounded-lg dark:bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg
}
/* kulaté pozadí kolem ikony - červené pozadí*/
.dialog-dotaz-ikona-pozadi-cervenykruh {
  @apply mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-12 sm:w-12
}
/* kulaté pozadí kolem ikony - oranžové pozadí*/
.dialog-dotaz-ikona-pozadi-oranzovykruh {
  @apply mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-200 sm:mx-0 sm:h-12 sm:w-12
}
.dialog-dotaz-ikona-cervena {
  @apply h-8 w-8 text-red-600
}
.dialog-dotaz-ikona-oranzova {
  @apply h-8 w-8 text-orange-600
}
/* Dialogové okno - nadpis - potvrzovací okno - červený design*/
.dialog-dotaz-nadpis-cerveny {
  @apply text-lg px-2 py-1 font-medium leading-6 text-gray-900 dark:text-gray-100 bg-red-100 dark:bg-red-500 rounded w-fit border dark:border-red-500
}
/* Dialogové okno - nadpis - potvrzovací okno - oranzovy design*/
.dialog-dotaz-nadpis-oranzovy {
  @apply text-lg px-2 py-1 font-medium leading-6 text-gray-900 dark:text-gray-100 bg-orange-100 dark:bg-orange-500 rounded w-fit border dark:border-orange-500
}
/* Dialogové okno - obsah - potvrzovací okno */
.dialog-dotaz-obsah {
  @apply bg-white dark:bg-gray-800 dark:text-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4
}
/* Dialogové okno - text otázky - potvrzovací okno*/
.dialog-dotaz-otazka {
  @apply text-base text-gray-700 dark:text-gray-300
}
/* Dialogové potvrzovacví okno - zápatí - pozadí a umístění tlačítek*/
.dialog-dotaz-zapati-pozadi {
  @apply bg-gray-100 dark:bg-gray-900 px-4 py-3 sm:flex sm:flex-row justify-end sm:px-6
}

/* Tlačítko - dotaz - akce - červené (např ukpončit, smazat apod.) */
.dialog-tlacitko-dotaz-akce {
  @apply cursor-pointer inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-base
}
/* Tlačítko - dotaz - akce - orange (např schválení, potvrzení apod.) */
.dialog-tlacitko-dotaz-schvaleni {
  @apply cursor-pointer inline-flex w-full justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-base
}
/* Tlačítko - dotaz - zrušit - šedé (např zrušit) */
.dialog-tlacitko-dotaz-zrusit {
  @apply cursor-pointer mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-base
}
/* Tlačítko - dialog - schválení - "oranžové" - v zápisu docházky*/
.dialog-tlacitko-schvaleni-otevreni {
  @apply text-white bg-orange-500 hover:bg-orange-600 cursor-pointer shadow-md hover:shadow-lg rounded-md px-3 sm:px-4 py-2 transition duration-500 select-none focus:outline-none dark:shadow-gray-700 dark:hover:shadow-gray-600
}

/* ----- Editační okna ----- */
/* Dialogové editační okno - rámeček*/
.dialog-edit-ramecek {
  @apply relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg
}
/* Dialogové editační okno - obsah */
.dialog-edit-obsah {
  @apply bg-white dark:bg-gray-800 dark:text-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4
}
/* Dialogové editační okno - malý nadpis */
.dialog-edit-nadpis-maly {
  @apply block text-base font-semibold leading-6 px-2 py-1 dark:text-gray-50 text-gray-900 bg-gray-100 dark:bg-gray-700 rounded w-fit border dark:border-gray-500
}
/* Dialogové editační okno - velký nadpis */
.dialog-edit-nadpis-velky {
  @apply text-lg font-medium mb-4 leading-6 px-2 py-1 text-gray-900 dark:text-gray-100 bg-gray-100 dark:bg-gray-700 rounded w-fit border dark:border-gray-500
}
/* Dialogové editační okno - zapati - pozadí */
.dialog-edit-zapati-pozadi {
  @apply bg-gray-100 dark:bg-gray-900 px-4 py-3 sm:flex sm:flex-row justify-end sm:px-6
}
/* Dialogové editační okno - tlačítko - akce - zelené */
.dialog-edit-tlacitko-akce {
  @apply cursor-pointer text-white bg-green-600 hover:bg-green-700 focus:ring-green-600 first-letter:inline-flex w-full justify-center rounded-md border border-transparent  px-4 py-2 text-base sm:text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto 
}   
/* Dialogové editační okno - tlačítko - zavřít  */
.dialog-edit-tlacitko-zavrit {
  @apply cursor-pointer text-gray-800 hover:bg-gray-300 border-gray-300 focus:ring-green-600 first-letter:transition mt-3 inline-flex w-full justify-center rounded-md border bg-white px-4 py-2 text-base sm:text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto
}   

/* Dialogové editační okno - ikona malá - oprava*/
.dialog-edit-ikona-oprava {
  @apply text-sm text-gray-500 dark:text-gray-500 transition-all hover:text-green-700 cursor-pointer
}   

/* Dialogové editační okno - ikona malá - odstranění*/
.dialog-edit-ikona-odstraneni {
  @apply text-sm text-gray-500 dark:text-gray-500 transition-all hover:text-red-600 cursor-pointer
}   

/* Dialogové editační okno - ikona malá - schválení*/
.dialog-edit-ikona-schvaleni {
  @apply text-sm text-gray-500 dark:text-gray-500 transition-all hover:text-orange-600 cursor-pointer
}  

/* Dialogové editační okno - ikona malá - informace*/
.dialog-edit-ikona-informace {
  @apply text-sm text-gray-500 dark:text-gray-500 transition-all hover:text-amber-600 cursor-pointer
}  

/* Dialogové editační okno - popis pole - label */
.dialog-edit-pole-popis {
  @apply block text-sm font-medium dark:text-gray-200 text-gray-700
}

/* Dialogové editační okno - popis checkboxu - label */
.dialog-edit-checkbox-popis {
  @apply text-sm mr-2 font-medium text-gray-700 dark:text-gray-200
}

/* Dialogové editační okno - pole - input */
.dialog-edit-pole-input {
  @apply mt-1 block w-full rounded-md border border-gray-300 dark:bg-gray-900 dark:border-black bg-white py-2 px-3 shadow-sm focus:border-green-600 focus:outline-none focus:ring-green-600 sm:text-sm disabled:bg-gray-300 disabled:dark:bg-gray-500 disabled:cursor-not-allowed
}
/* Dialogové editační okno - pole select */
.dialog-edit-pole-select {
  @apply mt-1 block w-full rounded-md border border-gray-300 dark:bg-gray-900 dark:border-black bg-white py-2 px-3 shadow-sm focus:border-green-600 focus:outline-none focus:ring-green-600 sm:text-sm
}
/* Dialogové editační okno - pole checkbox */
.dialog-edit-pole-checkbox {
  @apply block rounded-md border border-gray-300 py-2 dark:bg-gray-900 dark:border-black dark:text-white px-3 shadow-sm focus:border-green-600 focus:outline-none focus:ring-green-600 sm:text-sm
}

/* Dialogové editační okno - nápověda přes pole ReactMarkdown */
.dialog-edit-pole-napoveda {
  @apply pl-2 mt-1 block w-full rounded-md py-2 bg-gray-100 dark:bg-gray-700 dark:text-white px-3 shadow-sm sm:text-xs
}

/* Dialogové informační okno - popis pole - label (tučně - viz. např informace o rezervaci*/
.dialog-info-pole-popisB {
  @apply block text-sm font-bold text-gray-700 dark:text-gray-50
}
/* Dialogové informační okno - pole s daty - div  - viz. např informace o rezervaci */
.dialog-info-pole-div {
  @apply mt-1 text-sm w-full border border-gray-300 dark:bg-gray-700 bg-gray-100 shadow-sm dark:border-gray-900 px-3 py-2 rounded-lg
}
/* Dialogové informační okno - pole s daty - div - zvýrazněné pro důležité info - viz. např informace o rezervaci */
.dialog-info-pole-div-zvyrazneni {
  @apply font-bold mt-1 text-sm w-full border-2 border-gray-300 dark:bg-gray-700 bg-gray-100 shadow-sm dark:border-gray-900 px-3 py-2 rounded-lg
}


/* ----- Dialog - přehledy (měsíc, rok) ----- */
/* spec. pro obsah dialogového okna
/* Přehledy - popisky tucne */
.dialog-prehledy-popisB {
  @apply font-bold text-base
}
/* Přehledy - popisky medium */
.dialog-prehledy-popisM {
  @apply font-medium text-base
}
/* Přehledy - obsah / informace*/
.dialog-prehledy-obsah {
  @apply font-normal text-base
}
/* Přehledy - záhlaví tabulky - sticky */
.dialog-prehledy-zahlavi {
  @apply sticky top-0 font-medium text-sm bg-gray-100 dark:bg-gray-700 border-x-2 border-white dark:border-gray-800 
}
/* Přehledy - obsah - tabulky - menší velikost  */ 
.dialog-prehledy-obsah-tab {
  @apply font-normal text-sm
}


/* ---------------------------------- DOCHÁZKA ----------------------------------- */

/* Docházka / plocha - přehledy - "amber" */
.tlacitko-plocha-prehledy {
  @apply text-white bg-amber-600 hover:bg-amber-700 cursor-pointer shadow-md hover:shadow-lg rounded-md px-4 py-2 m-2 transition duration-500 select-none focus:outline-none dark:shadow-gray-700 dark:hover:shadow-gray-600
}
/* Docházka / plocha - zápis docházky - "green" */
.tlacitko-plocha-zapis {
  @apply  shadow-md hover:shadow-lg bg-green-700 text-white rounded-md px-4 py-2 m-2 transition duration-500 select-none hover:bg-green-800 focus:outline-none dark:shadow-gray-700 dark:hover:shadow-gray-600
}
/* Docházka / zápis docházky - menší tlačítko pro zápis udalosti - "green" */
.tlacitko-dialog-zapisUdalosti-male {
  @apply  shadow-md hover:shadow-lg cursor-pointer bg-green-700 text-white rounded-md px-3 sm:px-4 py-2 transition duration-500 select-none hover:bg-green-800 focus:outline-none dark:shadow-gray-700 dark:hover:shadow-gray-600 
}

/* --- Docházka - hlavní tlačítka (viz. Tlacitka.jsx) */
/* Docházka / plocha - hlavní / přímá tlačítka - konec prac dne */
.tlacitko-plocha-KonecPD {
  @apply bg-red-500 hover:bg-red-600 disabled:bg-slate-400 shadow-md hover:shadow-lg text-white rounded-md px-4 py-2 transition duration-500 dark:shadow-gray-700 dark:hover:shadow-gray-600 select-none focus:outline-none disabled:cursor-not-allowed disabled:hover:shadow-md
}
/* Docházka / plocha - hlavní / přímá tlačítka - začátek události */
.tlacitko-plocha-ZacatekUdal {
  @apply bg-green-600 hover:bg-green-700 disabled:bg-slate-400 shadow-md hover:shadow-lg text-white rounded-md px-4 py-2 transition duration-500 dark:shadow-gray-700 dark:hover:shadow-gray-600 select-none focus:outline-none disabled:cursor-not-allowed disabled:hover:shadow-md
}
/* Docházka / plocha - hlavní / přímá tlačítka - začátek události */
.tlacitko-plocha-KonecUdal {
  @apply bg-orange-500 hover:bg-orange-600 disabled:bg-slate-400 shadow-md hover:shadow-lg text-white rounded-md px-4 py-2 transition duration-500 dark:shadow-gray-700 dark:hover:shadow-gray-600 select-none focus:outline-none disabled:cursor-not-allowed disabled:hover:shadow-md
}


/* ----- Docházka - Plocha - přehledy (den, týden) ----- */
/* Plocha - přehledy - rámeček*/
.plocha-prehledy-ramecek {
  @apply bg-white dark:bg-gray-800 dark:text-gray-300 w-fit px-4 mt-5 pb-4 sm:p-6 sm:pb-4 mr-6 rounded-md shadow-md bg-blend-lighten hover:bg-blend-darken
}
/* Plocha - přehledy - nadpis*/
.plocha-prehledy-nadpis {
  @apply text-base leading-6 font-medium text-gray-900 dark:text-white px-2 py-1 bg-gray-100 dark:bg-gray-700 rounded w-fit border dark:border-gray-500
}
/* Plocha - přehledy - zvýraznění řádku - hover*/
.plocha-prehledy-hover {
  @apply hover:bg-gray-200 dark:hover:bg-gray-900
}
/* Plocha - přehledy - záhlaví tabulky*/
.plocha-prehledy-zahlavi {
  @apply font-medium text-sm bg-gray-100 dark:bg-gray-700 border-x-2 border-white dark:border-gray-800 
}
/* Plocha - přehledy - popisky */
.plocha-prehledy-popis {
  @apply font-normal text-sm
}
/* Plocha - přehledy - popisky tucne */
.plocha-prehledy-popisB {
  @apply font-bold text-sm
}
/* Plocha - přehledy - popisky medium */
.plocha-prehledy-popisM {
  @apply font-medium text-sm 
}
/* Plocha - přehledy - obsah / informace*/
.plocha-prehledy-obsah {
  @apply font-normal text-sm
}


/* ----- Barevné odlišení událostí s chybou -----*/
/* Chyba 1 - nepotvrzený čas */
.doch-udal-nepotvrzeno {
  @apply bg-yellow-100 dark:text-gray-900
}
/* Chyba 2 - neukončeno*/
.doch-udal-neukon {
  @apply bg-pink-100 dark:text-gray-900
}
/* Chyba 3 - chybí oběd*/
.doch-obed-chybi {
  @apply bg-orange-100 dark:text-gray-900
}
/* Chyba 3 - absence PD*/
.doch-udal-absence {
  @apply bg-red-300 dark:text-gray-900
}

/* ----- Barevné odlišení typů událostí (např. v Zápisu docházky) -----*/
/* Typ udalosti: Pracovní den */
.doch-typudal-1PD {
  @apply bg-blue-200 hover:bg-blue-300 border-blue-300
}
/* Typ udalosti: Přerušení*/
.doch-typudal-2PRE {
  @apply  text-black dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 border-gray-300 dark:border-blue-600
}
/* Typ udalosti: Dovolená */
.doch-typudal-3DOV {
  @apply bg-green-200 hover:bg-green-300 border-green-300
}
/* Typ udalosti: Nemoc */
.doch-typudal-4NEM {
  @apply bg-red-200 hover:bg-red-300 border-red-300
}
/* Typ udalosti: Služ.cesta */
.doch-typudal-5SC {
  @apply bg-orange-200 hover:bg-orange-300 border-orange-300
}
/* Typ udalosti: Soc.volno */
.doch-typudal-6SV {
  @apply bg-green-100 hover:bg-green-200 border-green-300
}
/* Typ udalosti: Home Office */
.doch-typudal-7HO {
  @apply bg-orange-100 hover:bg-orange-200 border-orange-200
}
/* Typ udalosti: Ostatni volno */
.doch-typudal-8OV {
  @apply bg-lime-200 hover:bg-lime-300 border-lime-300
}


/* --------------- KONTAKTY ---------------- */
/* plocha - hlavní div  */
.kontakty-plocha-hlavni-div {
  @apply dark:text-gray-300 px-4 pt-5 pb-4 sm:p-6 sm:pb-4
}
/* plocha - oblast seznamu kontaktů  */
.kontakty-plocha-seznam {
  @apply mt-6 bg-white dark:bg-gray-800 dark:text-gray-300 sm:p-6 sm:pb-4 rounded-md shadow-md px-3 py-4
}
/* plocha - aktivní tlačítko pro výběr zobrazení kontaktů */
.kontakty-plocha-tlacitko-aktivni {
  @apply bg-amber-600 text-white py-3 
}
/* plocha - NEaktivní tlačítko pro výběr zobrazení kontaktů */
.kontakty-plocha-tlacitko-neaktivni {
  @apply text-amber-700 dark:text-amber-100 py-3 
}
/* plocha - tlačítko pro výběr zobrazení kontaktů - další společné vlastnosti*/
.kontakty-plocha-hlavni-tlacitko {
  @apply border border-amber-600 rounded-md w-fit cursor-pointer hover:bg-amber-700 shadow-sm hover:text-white shadow-amber-100 dark:shadow-none
}
/* plocha - zobrazení času aktualizace */
.kontakty-plocha-aktualizace {
  @apply text-sm bg-gray-200 dark:bg-gray-700 p-1 rounded-md
}
/* plocha - záhlaví tabulky seznamu kontaktů - sticky*/
.kontakty-plocha-tabulka-zahlavi {
  @apply sticky top-0 bg-gray-300 dark:bg-gray-900
}
/* plocha - seznam kontaktů - tabulka - velikost */
.kontakty-plocha-tabulka-MinMaxVelikost {
  @apply mt-1 relative min-h-[500px] max-h-[600px] min-w-[800px] overflow-auto
}
/* plocha - seznam kontaktů - obsah */
/* původně byl border, ale dark módu to dělalo prasečinu
text-left px-6 border-gray-300 dark:border-gray-700 transition-all border-t text-sm  */ 
.kontakty-plocha-tabulka-obsah {
  @apply text-left px-6 transition-all border-b text-sm 
}

/* Okno pro zobrazení detailu kontaktu */
/* plocha okna */
.kontakty-okno-plocha {
  @apply bg-white dark:bg-gray-800 dark:text-gray-300  px-4 pt-5 pb-4 sm:p-6 sm:pb-4
}
/* nadpis okna */
.kontakty-okno-nadpis {
  @apply text-lg leading-6 font-medium text-gray-900 bg-gray-300 dark:text-gray-300 dark:bg-gray-900 rounded-md py-2 px-4
}
/* obsah okna */
.kontakty-okno-obsah {
  @apply text-gray-900 dark:text-gray-300 border dark:border-gray-600 rounded-md py-2 px-4 
}
/* zapatí okna */
.kontakty-okno-zapati {
  @apply bg-gray-100 dark:bg-gray-900 dark:text-gray-300 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse
}
/* okn- tlačítko zavřít */
.kontakty-okno-tlacitko-zavrit {
  @apply dark:bg-gray-500 transition-all dark:hover:bg-gray-800 dark:text-white dark:border-black inline-flex w-full justify-center rounded-md border border-gray-300 bg-gray-200 px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
}

/* ----- Barvy kontaktů ----- */
/* plocha - seznam kontaktů - barva - duvod nepřítomnosti */
.kontakty-plocha-barva-DN0 {
  @apply shadow-green-200 bg-green-200 dark:bg-green-800 dark:hover:bg-green-900 border-green-300 dark:border-green-700 hover:bg-green-300
}
.kontakty-plocha-barva-DN1 {
  @apply shadow-gray-200 bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 border-gray-300 dark:border-gray-600 hover:bg-gray-300
}
.kontakty-plocha-barva-DN2 {
  @apply shadow-red-200 bg-red-200 dark:bg-red-800 dark:hover:bg-red-900 border-red-300 dark:border-red-600 hover:bg-red-300
}
.kontakty-plocha-barva-DN3 {
  @apply shadow-pink-200 bg-pink-200 dark:bg-pink-800 dark:hover:bg-pink-900 border-pink-300 dark:border-pink-600 hover:bg-pink-300
}
.kontakty-plocha-barva-DN4 {
  @apply shadow-orange-200 bg-orange-200 dark:bg-orange-800 dark:hover:bg-orange-900 border-orange-300 dark:border-orange-600 hover:bg-orange-300
}
.kontakty-plocha-barva-DN5 {
  @apply hover:bg-gray-300 dark:text-gray-100 dark:hover:bg-gray-900 border-gray-300 dark:border-gray-600
}
/* ----- Speciální prvky ------ */
/* použito u emailu */
.kontakty-okno-dynamicke-podtrzeni {
  @apply hover:border-b hover:border-black dark:hover:border-white font-semibold
}


/* --------------- VOZIDLA ---------------- */

/* plocha - hlavní div  */
.vozidla-plocha-hlavni-div {
  @apply dark:text-gray-300 px-4 pt-5 pb-4 sm:p-6 sm:pb-4
}
/* plocha - oblast seznamu rezervací  */
.vozidla-plocha-seznam {
  @apply mt-6 bg-white dark:bg-gray-800 dark:text-gray-300 sm:p-6 sm:pb-4 rounded-md shadow-md px-3 py-4
}
/* plocha - aktivní tlačítko pro výběr zobrazení rezervací */
.vozidla-plocha-tlacitko-aktivni {
  @apply bg-amber-600 text-white 
}
/* plocha - NEaktivní tlačítko pro výběr zobrazení rezervací */
.vozidla-plocha-tlacitko-neaktivni {
  @apply text-amber-700 dark:text-amber-100
}
/* plocha - tlačítko pro výběr zobrazení rezervací - další společné vlastnosti*/
.vozidla-plocha-hlavni-tlacitko {
  @apply border border-amber-600 rounded-md w-fit cursor-pointer hover:bg-amber-700 shadow-sm hover:text-white shadow-amber-100 dark:shadow-none         
}        

/* plocha - tlačítko pro výběr zobrazení historie rezervací - další společné vlastnosti*/
.vozidla-dialog-hlavni-tlacitko {
  @apply border border-amber-600 hover:bg-amber-700 hover:text-white cursor-pointer rounded-md px-3 sm:px-4 py-2 transition duration-500 select-none focus:outline-none 
}        


/* Box pro zobrazení informací rezervaci */
.vozidla-zobrazeni-rezervace {
  @apply dark:text-gray-100 text-center dark:shadow-sm px-2 my-[2px] py-[2px] shadow-md rounded-md border transition-all text-sm
}
.vozidla-zobrazeni-rezervace-historie {
  @apply text-center dark:shadow-sm px-2 my-[2px] py-[2px] shadow-md rounded-md border transition-all text-sm shadow-white text-gray-200 bg-black border-gray-300 dark:text-gray-200 
}
.vozidla-zobrazeni-rezervace-cileSPZ {
  @apply flex items-center pl-2 pr-5 border my-2 rounded-md shadow-md py-1 dark:shadow-sm
}



.vozidla-zobrazeni-NazevSkupRez {
  @apply px-2 font-semibold text-center text-lg py-5
}

.vozidla-tabulka-MinMaxVelikost{
  @apply relative min-h-[500px] max-h-[600px] min-w-[800px] overflow-auto
}
.vozidla-tabulka-zahlavi-sticky{
  @apply sticky top-0 bg-white dark:bg-gray-800
}
.vozidla-tabulka-zahlavi-sticky{
  @apply sticky top-0 bg-white dark:bg-gray-800
}
/* vzhled řádků tabulek - border dole a hover */
/* hover:bg-gray-200 hover:dark:bg-gray-700 */
.vozidla-tabulka-radek{
  @apply rounded-sm border-y dark:border-gray-500 
}

/* ----- Barvy - přečtení informace o rezervaci, zrušení rezervace ----- */
/* využito v editačním okně rezervací */
.vozidla-barva-rez-precteno {
  @apply text-green-600 dark:text-green-400
}
.vozidla-barva-rez-neprecteno {
  @apply text-gray-700 dark:text-gray-50
}
.vozidla-barva-rez-zrusena {
  @apply block text-sm font-medium text-red-500 dark:text-red-400
}



/* ----- Barvy rezervací ----- */
.vozidla-barva-rez-RC1 {
  @apply shadow-red-200 bg-red-200 dark:bg-red-800 border-red-300 hover:bg-red-300 hover:dark:bg-red-900
}
.vozidla-barva-rez-RC2 {
  @apply shadow-pink-200 bg-pink-200 dark:bg-pink-800 border-pink-300 hover:bg-pink-300 hover:dark:bg-pink-900
}
.vozidla-barva-rez-RC3 {
  @apply shadow-orange-200 bg-orange-200 dark:bg-orange-800 border-orange-300 hover:bg-orange-300 hover:dark:bg-orange-900
}
.vozidla-barva-rez-RC4 {
  @apply shadow-green-200 bg-green-200 dark:bg-green-800  border-green-300 hover:bg-green-300 hover:dark:bg-green-900 
}
.vozidla-barva-rez-RC5 {
  @apply shadow-stone-200 bg-stone-200 dark:bg-stone-800  border-stone-300 hover:bg-stone-300 hover:dark:bg-stone-900
}
.vozidla-barva-rez-RC9 {
  @apply shadow-white bg-white border-gray-300 dark:bg-gray-600 dark:text-gray-300 dark:shadow-sm hover:bg-gray-100 hover:dark:bg-gray-700
}



/* --------------- ADMINISTRACE ---------------- */
/* základní modré tlačítko v nabídce administrace */
.admin-tlacitko-nabidka {
  @apply cursor-pointer shadow-md hover:shadow-lg bg-blue-500 text-white rounded-md px-4 py-2 m-2 transition duration-500 select-none hover:bg-blue-600 focus:outline-none         
}
/* základní modré tlačítko v dialog. okně pro provedení akce  */
.admin-dialog-tlacitko-akce {
  @apply shadow-md hover:shadow-lg cursor-pointer bg-blue-500 text-white rounded-md px-3 sm:px-4 py-2 transition duration-500 select-none hover:bg-blue-600 focus:outline-none
}
/* modré (světle) tlačítko pro přepnutí zobarazení  */
.admin-dialog-tlacitko-prepinac {
  @apply shadow-md hover:shadow-lg cursor-pointer bg-blue-400 text-white rounded-md px-3 sm:px-4 py-2 transition duration-500 select-none hover:bg-blue-500 focus:outline-none 
}
/* Dialogové editační okno - pole - input */
.admin-dialog-edit-pole-input {
  @apply mt-1 block w-full rounded-md border border-gray-300 py-2 dark:bg-gray-900 dark:border-black dark:text-white px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm
}
/* Dialogové editační okno - pole select */
.admin-dialog-edit-pole-select {
  @apply mt-1 block w-full rounded-md border border-gray-300 dark:bg-gray-900 dark:border-black bg-white py-2 px-3 shadow  sm:text-sm
}
/* Dialogové editační okno - pole checkbox */
.admin-dialog-edit-pole-checkbox {
  @apply block rounded-md border border-gray-300 py-2 dark:bg-gray-900 dark:border-black dark:text-white px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm
}
/* Dialogové editační okno - akce  */
.admin-dialog-edit-tlacitko-akce {
  @apply cursor-pointer inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm
}
/* Dialogové editační okno - zavřít  */
.admin-dialog-edit-tlacitko-zavrit {
  @apply cursor-pointer mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
}
/* Dialogové editační okno - ikona malá - pro admin. */
.admin-dialog-edit-ikona-oprava {
  @apply text-sm text-gray-500 dark:text-gray-500 transition-all hover:text-blue-600 dark:hover:text-blue-400 cursor-pointer
}  
/* Dialogové editační okno - sakce s údaji - zvýraznění nadřazeného divu  */
.admin-dialog-edit-sekce {
  @apply bg-gray-200 dark:bg-gray-700 rounded-md px-4 py-2
}  

/* Administrace - plocha - nabídka - popis skupiny tlačítek  */
.admin-plocha-nabidka-popis {
  @apply font-bold text-blue-900
}  
/* Administrace - plocha - nabídka - pozadí pod tlačítky  */
.admin-plocha-nabidka-pozadi {
  @apply w-full bg-blue-100 p-4 rounded-lg
}  



/*--- Select pro výběr vozidla ---*/
.admin-vyber-vozidla-select {
  @apply font-bold cursor-pointer text-base py-2 px-2 bg-gray-300 dark:bg-gray-700 rounded-md hover:bg-gray-400 hover:dark:bg-gray-600
}
.admin-vyber-vozidla-option {
  @apply bg-gray-300 dark:bg-gray-700
}